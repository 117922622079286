<template>
  <v-container fluid>
    <v-row class=" p-y-6 mb-5">
      <v-col lg="3" sm="12">
        <BackButton :handler="goToPromotion"/>
      </v-col>
      <v-col lg="5" md="5" sm="12">
        <div class="d-flex justify-center gap-x-6 align-center">
          <div style="max-width: 16rem;max-height: 10rem">
            <view-image :image="promotionDetails.image_path ? promotionDetails.image_path : ''"  :width="200" :height="165" class="rounded-lg" defaultImage="promotion"/>
          </div>
          <div class="mt-2">
            <h3 class="text-xl m-b-4">
              {{ promotionDetails.name }}
            </h3>
            <div class="d-block ">
              <SvgIcon class="gap-x-4 m-b-4 font-medium">
                <template v-slot:icon>
                  <CalenderIcon/>
                </template>
               Start Date:  {{ promotionDetails.start_date }}
              </SvgIcon>
            </div>
            <div class="d-block">
              <SvgIcon class="gap-x-4 m-b-4 font-medium">
                <template v-slot:icon>
                  <CalenderIcon/>
                </template>
                End Date:  {{ promotionDetails.end_date }}
              </SvgIcon>
            </div>


          </div>
        </div>

      </v-col>
      <v-col lg="4" sm="12" class="mt-sm-3  d-sm-flex">


        <v-btn
            v-if="
          checkDeletePermission($modules.promotions.management.slug)
        "
            class="ma-2 text_capitalize "
            @click="
            deletePromotionWarning({
              id: promotion.id,
              status_id: promotionDetails.status_id,
              flag: true,
              type:promotionDetails.status_id == 1 ?'delete':'activate',
            })
          "
            outlined
            :color="promotionDetails.status_id == 1?'red':''"
        >
          <span class="ml-1">{{ promotionDetails.status_id == 1 ? "Deactivate" : "Activate" }}</span>
        </v-btn>
        <v-btn
            v-if="
          promotionDetails.usage_type == 'S'  &&
            promotionDetails.status_id == 1
            &&
            checkWritePermission($modules.promotions.management.slug)
        "

            style="background-color: rgba(17, 42, 70, 0.1) ; color:#112A46 "
            class="ma-2  text_capitalize "
            outlined
            @click="managePromotion()"
        >
          <span class="ml-1">Manage</span>   </v-btn>
        <v-btn
            style="background-color: rgba(17, 42, 70, 0.1) ; color:#112A46 "
            class="ma-2  text_capitalize "
            outlined

            @click="editPromotion()"
        > <EditBtnIcon/>
          <span class="ml-1">Edit</span>   </v-btn>
      </v-col>
    </v-row>



    <div class="row mt-5">
      <div class="col-12">
        <v-card class="shadow-0">
          <v-card-actions class="text-center " >
            <v-tabs
                background-color="transparent"
                centered
                light
                class="q-tabs-secondary shadow-0 border-bottom"
                slider-color="transparent"
                v-model="tab"
            >
              <v-tab href="#details"
                     active-class="active_tab_stroke_icon"
              >
                <SvgIcon text="Details">
                  <template v-slot:icon>
                    <PackagesIcon/>
                  </template>
                </SvgIcon>
              </v-tab>


              <v-tab href="#logs"
                     active-class="active_tab_fill_icon"
              >
                <SvgIcon text="Logs">
                  <template v-slot:icon>
                    <SalesIcon/>
                  </template>
                </SvgIcon>
              </v-tab>
            </v-tabs>
          </v-card-actions>


          <v-card-text>
            <v-row justify="center">
              <v-tabs-items v-model="tab">
                <v-tab-item value="details">
                  <v-row justify="center" >
                    <v-col md="8">
                      <v-card class="shadow-0 r">
                        <v-card-title>Description</v-card-title>
                        <v-card-text v-if="promotionDetails.description"
                                     v-html="promotionDetails.description"
                        ></v-card-text>
                        <v-card-text v-else
                        >
                          <h4 class="text--primary">No description found!</h4>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row class="mt-5" justify="center">
                    <v-col md="8">
                      <v-card class="shadow-0 rounded-5">
                        <v-card-title>Benefits</v-card-title>
                        <v-card-text>
                          <v-simple-table class="table table-responsive">
                            <template v-slot:default>
                              <thead>
                              <tr class="tr-neon tr-rounded opacity-70">
                                <th class="">Type</th>
                                <!-- <th class="tableHeader">Benefits</th> -->
                                <th class="">Benefit Type</th>
                                <th class="">Amount</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr
                                  v-for="(benefit, index) in promotionDetails.benefits"
                                  :key="index"
                              >
                                <td>{{ benefit.product_type.name }}</td>
                                <!-- <td>{{ benefit.benefit }}</td> -->

                                <td>
                                  {{
                                    benefit.benefit_type == "P" ? "Percentage" : "Amount"
                                  }}
                                </td>
                                <td>
                                  {{ benefit.benefit }}
                                  {{
                                    benefit.benefit_type == "P" ? " %" : " " + currencyCode
                                  }}
                                </td>
                              </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>


                <v-tab-item value="logs">
                  <v-row justify="center">
                    <event-logs
                        v-if="promotion.id"
                        :global="false"
                        :promotionId="promotion.id"
                        :exportPermission="
          checkExportPermission($modules.promotions.management.slug)
        "
                    ></event-logs>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-row>


          </v-card-text>
        </v-card>
      </div>
    </div>




    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import EventLogs from "../Sales/Logs";
import BackButton from "@/components/Common/BackButton.vue";
import CalenderIcon from "@/assets/images/promotions/calendar.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import EditBtnIcon from "@/assets/images/misc/pencil-icon.svg";
import SalesIcon from "@/assets/images/promotions/logs.svg";
import PackagesIcon from "@/assets/images/promotions/details.svg";

export default {
  components: {
    SalesIcon,
    EditBtnIcon,
    SvgIcon, CalenderIcon,
    BackButton,
    EventLogs,
    PackagesIcon
  },
  data() {
    return {
      tab: "details",
      promotion: {},
      promotionDetails: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    };
  },
  mounted() {
    if (typeof this.$route.params.data != "undefined") {
      this.promotion.id = parseInt(atob(this.$route.params.data));
      this.getPromotionDetails();
    }
  },
  methods: {
    editPromotion() {
      this.$router.push({
        name: "EditPromotion",
        params: { data: btoa(this.promotion.id) },
      });
    },

    managePromotion(){
      this.$router.push({
        name: "ManagePromotion",
        params: { data: btoa(this.promotion.id) },
      });
    },



    deletePromotionWarning(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${data.type} this Promotion?`,
        description: `By clicking <b>Yes</b> you can confirm ${data.type} this promotion.  Do you need to continue your action ?`,
        type: "delete",
      };
    },
    confirmActions(data) {
      if (data.type == "delete") {
        this.changePromotionStatus(data.id);
      }
      this.confirmModel.id = null;
    },
    changePromotionStatus(id) {
      this.$http
          .put("venues/promotions/" + id + "/status")
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.getPromotionDetails();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    getPromotionDetails() {
      this.showLoader("Loading");
      this.$http
        .get("venues/promotions/" + this.promotion.id)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status) {
            this.promotionDetails = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    goToPromotion() {
      this.$router.push({ name: "Promotions" }, () => {});
    },
  },
};
</script>

<style scoped>
.tab_active {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.flat-table {
  display: block;
  overflow: auto;
  width: auto;
  border-radius: 10px;
}
.flat-table th {
  background-color: #3e4c55;
  color: white;
  font-weight: normal;
  padding: 8px 12px;
  text-align: center;
}
.flat-table td {
  background-color: #eeeeee;
  color: #6f6f6f;
  padding: 8px 12px;
}
tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}
</style>
